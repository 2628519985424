<template>
  <div class="daily-poetry">
    <div class="daily-bg">
      <div class="banner-head">
        <h1>每日古诗文</h1>
        <p class="banner-tip">每天5分钟，一年吃透300个古诗文名句名段</p>
        <div class="banner-list">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/white-yes-icon.png">
          <p>北师大、浙大硕博士团队研发</p>
        </div>
        <div class="banner-list">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/white-yes-icon.png">
          <p>分年级课程、原创古诗文动画剧、名师权威讲解</p>
        </div>
        <div class="banner-list">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/white-yes-icon.png">
          <p>经典诵读AI智能打分</p>
        </div>
        <span class="buy-btn" @click="openMask(4)">立即报名</span>
      </div>
        <img  src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poetry-icon.png" alt="每日古诗文" class="read-icon">
    </div>
    <!-- 课程介绍 -->
    <div class="class-info">
      <div class="container">
        <div class="info">
          <!-- <div class="info-left">
            <img @click="videoPlay" v-if="showVideoBg" class="video-bg" src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poetry-videobg.jpg" alt="">
            <video
              ref="dailyPoetryVideo"
              class="video-player"
              controls
              name="media"
              controlslist="nodownload"
              :class="{hide: showVideoBg}"
            >
              <source
                src="https://xcx.alicdn2.hexiaoxiang.com/web_static/zh_gsw.mp4"
                type="video/mp4"
              >
            </video>
          </div> -->
          <div class="info-right">
            <h5 class="info-title">课程介绍</h5>
            <p
              class="info-detail"
            >课程按由浅入深的顺序编排，从传世的经、史、子、集中，精选出300个脍炙人口、含义深刻的千古名句名段，覆盖了诗、词、曲、文言文四大类别；包含了孔子、孟子、李白、杜甫、司马迁等100多位古代大思想家和大文豪的作品；涉及《诗经》、《论语》、《孟子》、《史记》等100多部伟大作品。</p>
          </div>
        </div>
        <div class="new-style info">
          <div class="content">
            <div class="content-tit">学一学</div>
            <p class="content-det">小学古诗文总数增加了55篇，增幅高达80%</p>
            <p class="content-det">初中古诗文总篇数占到了全部课文的51.7%</p>
            <p class="content-det">高考必考古诗文增加了5倍</p>
          </div>
          <img
            src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poetry1.png"
            class="img-box img-center"
          >
        </div>
        <div class="class-resule info">
          <img
            src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poetry2.png"
            class="img-box img-left"
          >
          <div class="content">
            <div class="content-tit">课程效果</div>
            <p class="content-det">5分钟课程结合Q版动画情景剧，注意力集中</p>
            <p class="content-det">4大板块科学设计，环环相扣，记忆点加深</p>
            <p class="content-det">每天6:00解锁，晨读让古诗文吸收事半功倍 ·300天日积月累，接受中华传统文化熏陶</p>
          </div>
        </div>
        <div class="ai-mark info">
          <div class="content">
            <div class="content-tit">AI智能诵读打分</div>
            <p class="content-det">我们从完整度、流利度声韵母、流利度、声调，四个方面检测对孩子诵读进行评分，让孩子更有目的性的诵读训练。</p>
          </div>
          <img
            src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poetry3.png"
            class="img-box img-center"
          >
        </div>
      </div>
    </div>
    <!-- 课程安排 -->
    <div class="schedule">
      <div class="container">
        <h5 class="title">
          课程
          <span>安排</span>（部分）
        </h5>
        <!-- <div class="table-head table-item">
          <span class="first">课时</span>
          <span class="sec">选自</span>
          <span class="third">课程</span>
        </div>
        <div class="table-body">
          <div class="table-item">
            <span class="first">第一讲</span>
            <span class="sec">《史记·陈涉世家》</span>
            <span class="third">鸿鹄之志</span>
          </div>
          <div class="table-item">
            <span class="first">第二讲</span>
            <span class="sec">《史记·淮阴侯列传》</span>
            <span class="third">多多益善</span>
          </div>
          <div class="table-item">
            <span class="first">第三讲</span>
            <span class="sec">《舟过安仁》</span>
            <span class="third">舟过安仁</span>
          </div>
          <div class="table-item">
            <span class="first">第四讲</span>
            <span class="sec">《史记·廉颇蔺相如列传》</span>
            <span class="third">完璧归赵</span>
          </div>
          <div class="table-item">
            <span class="first">第五讲</span>
            <span class="sec">《宿建德江》</span>
            <span class="third">鸿鹄之志</span>
          </div>
          <div class="table-item">
            <span class="first">第六讲</span>
            <span class="sec">《史记·高祖本纪》</span>
            <span class="third">约法三章</span>
          </div>
          <div class="table-item">
            <span class="first">第七讲</span>
            <span class="sec">《淮南子·兵训传》</span>
            <span class="third">声东击西</span>
          </div>
        </div>
        <div class="table-sum">共365讲</div> -->
        <dailyArrage :primaryCourse="primaryCourse" :midCourse="midCourse"></dailyArrage>
      </div>
    </div>
    <!-- 配套教材 -->
    <div class="book">
      <div class="book-contain">
        <h2>配套教材</h2>
        <div class="book-btn">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poem-btn1.png" >
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poem-btn2.png" >
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poem-btn3.png" >
        </div>
       <div class="book-img">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poem-book1.png" >
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poem-book2.png" >
       </div>
      </div>
    </div>
    <!-- 上课流程 -->
    <div class="process">
      <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/class-process-poetry.png" alt="上课流程">
    </div>
    <!-- 我们承诺 -->
    <div class="commitment">
      <img
        src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/commitment-grey.png"
        alt="服务承诺"
      >
    </div>
    <!-- 家长反馈 -->
    <feedBack 
      postImg="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/fb-poetry.png" 
      :list="feedBack" rgba="color: rgba(29, 186, 92, 1)" title="true">
    </feedBack>
    <quickTool :downloadShow="showData"></quickTool>
    <!-- 弹窗 -->
    <enterMask :showMask="open" :imgUrl="url" @onClose="open=false"></enterMask>
  </div>
</template>

<script>
import feedBack from "../components/feedBack";
import quickTool from "@/components/quickTool";
import enterMask from "@/components/enterMask";
import dailyArrage from '@/components/dailyArrage'
export default {
  name: "dailyPoetry",
  components: { feedBack, quickTool, enterMask,dailyArrage },
  data() {
    return {
      showData: false,
      open: false,
      url: "",
      feedBack: [
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLoibQeYakloErOhFMEFibyW5YpIXNib5JLsoNXsk8GHWt6Sfxpe2u2GG7YV47jqKibAsFd6UXKF1gATg/132",
          name: "木子",
          grade: "年级",
          desc:
            "昨天孩子上了一节，喜欢的不得了！正好新加坡有考口语和听力，孩子这些方面有点不好！平时读得卡卡，声音有小。昨天的课，他反反复复，越读越大声！最后不尽兴，还要多度一段"
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/vcEHqs1t1MViaNibdWASoP20Wd98PFCMf2XOxGP5LF7ibHtdAt2sHyB9TtibIFZBUx9Po3IIqS0VjXRH3c5D0Vfcfg/132",
          name: "小小",
          grade: "年级",
          desc:
            "他今天很高兴的说，老师今天扩展了课本内容，提问千里之行始于足下是什么意思，全班只有她一个人答对的，说每日古诗文的效果"
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/xpAsb3hl87UbMPCkYhgdvOtNUibYLrqtlvW97XhDFCLjJt84LrFcXTvG9ZYI91Vq7Cz2W7fd1k0q1kgJ4H7DibhA/132",
          name: "叶子薇",
          grade: "一年级",
          desc:
            "古诗文每天5分钟作为晨读最好啦~真正吃透其意，不在死记硬背，还可以运用到写作中哦"
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJOtpTHichlMq8Mw0P62b1WKkt4I8LpBheLGZHquIkJBwAtf8m1WRgwfUWj7Cicjmb0dn5CWpibj7DQw/132",
          name: "汶汶",
          grade: "一年级",
          desc:
            "孩子还是挺喜欢古诗文学习的，每天5分钟。学习完成作业，不停地朗读，为了成为大师。"
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/DYAIOgq83epEaTn4WutFZgTdxkrUwX0FsTP3B0fvEXlRaBHVYHGYJE8qKcichBdFMeY2a3FncVGSAUGJOl1v8WQ/132",
          name: "珊珊",
          grade: "二年级",
          desc: "这个评分系统太好了，孩子不服输的一直在读"
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTICYLxHamNQrpMImHB0ScDgnRaNHbvBGnso6eOeaVd2MlD1ibC7Oz2TNjj6CMD6wLg189yhRDLU1hQ/132",
          name: "王诺彦",
          grade: "一年级",
          desc: "宝贝自从上了古诗文以后，对古诗文兴趣挺大的，有时候还自己写诗"
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/bjN8AiaEicEURpdicpDpcVsvdLWTR3NrIgRqibmhM9fbu3DOQyOe5iaUzkbbY58VTXCv7UApjcnv0Ft1GUk4PxXibctw/132",
          name: "薇笑",
          grade: "一年级",
          desc:
            "比我还积极。前天我出差回来晚了，导致他当晚的大语文古诗词没完成，第二天早上醒来第一句就问，早上还能不能补读"
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/ZjGxPhssj80sec51JBx3iaKpcPLEU5ZHlxPXdgTKMPtGLP3oPolWhBnAK0oBjhtIhb0DneGqFeCMN4eQXjtibSHg/132",
          name: "唐诗涵",
          grade: "二年级",
          desc: "我的孙女她还是喜欢这门课，自己打开去学习，还喊我一起去打卡"
        },
        {
          avatar:
            "http://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqMT1eOahIUbLib6q9UHBsExUj0nuicPWBHGAibfibZpN8F7Whd84oKfqAgBO8picJb6PMy8NCo9AQpDgg/132",
          name: "龚苹",
          grade: "二年级",
          desc:
            "你们这种每天5分钟的课程我认为挺好的，太长了孩子有负担就不行了，这种方式对培养孩子的学习习惯有好处。"
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/aRzndKDDP7KsfvEicDaz1mP1xQqwJ2ZWm6uzuYwun5ZFiahze9giansK1pcNsKSx3bkNSvdQZicDLGkRwHTDOL9vaw/132",
          name: "珑月",
          grade: "一年级",
          desc:
            "他挺喜欢pk这项，有挑战力，平时一点都不喜欢看书、写字。现在都很主动将积极自己去学。"
        }
      ],
      primaryCourse:[
            {
            week:['1'],
           classic:'多多益善',
           knowledge:['臣多多益善耳'],
           power:['《史记·淮阴侯列传》']
           },
            {
            week:['2'],
           classic:'一鸣惊人',
           knowledge:['   不鸣则已，一鸣惊人。'],
           power:['  《史记·滑稽列传》']
           },
        {
            week:['3'],
           classic:'《迟日江山丽》 ',
           knowledge:[' 迟日江山丽，春风花草香。泥融飞燕子，沙暖睡鸳鸯。'],
           power:['  《绝句二首》（其一）']
           },
           {
            week:['4'],
           classic:'千里之行',
           knowledge:[' 千里之行，始于足下'],
           power:[' 《老子》']
           },
             {
            week:['5'],
           classic:'当断则断',
           knowledge:[' 当断不断，反受其乱。'],
           power:['《史记·齐悼惠王世家》']
           },
             {
            week:['6'],
           classic:'幼女词',
           knowledge:['幼女才六岁，未知巧与拙。向夜在堂前，学人拜新月。'],
           power:['《幼女词》']
           },
             {
            week:['7'],
           classic:'登黄鹤楼',
           knowledge:['叹江山如故，千村寥落！'],
           power:['  《满江红·登黄鹤楼有感》  ']
           },
             {
            week:['8'],
           classic:'知错就改',
           knowledge:['古之君子，过则改之。'],
           power:['《孟子·公孙丑章句下》']
           },
             {
            week:['9'],
           classic:'出类拔萃',
           knowledge:['出于其类，拔乎其萃。'],
           power:['《孟子·公孙丑章句上》']
           },
              {
            week:['10'],
           classic:'题画诗',
           knowledge:[' 竹外桃花三两枝，春江水暖鸭先知。蒌蒿满地芦芽短，正是河豚欲上时。'],
           power:['《惠崇春江晚景》']
           },
              {
            week:['11'],
           classic:'人生在勤',
           knowledge:['人生在勤，不索何获'],
           power:['《后汉书·张衡列传》']
           },
              {
            week:['12'],
           classic:'鸟尽弓藏',
           knowledge:['狡兔死，走狗烹；飞鸟尽，良弓藏。'],
           power:['《资治通鉴·汉纪三》']
           },
              {
            week:['13'],
           classic:'绳锯木断',
           knowledge:['绳锯木断，水滴石穿。'],
           power:['《鹤林玉露》']
           },
              {
            week:['14'],
           classic:'若要人不知',
           knowledge:['若要人不知，除非己莫为。'],
           power:['《增广贤文》']
           },
              {
            week:['15'],
           classic:'巧妙回绝',
           knowledge:['山中何所有，岭上多白云。只可自怡悦，不堪持赠君。'],
           power:['《诏问山中何所有赋诗以答》']
           },
              {
            week:['16'],
           classic:'后来居上',
           knowledge:['后来者居上。'],
           power:['《史记·汲郑列传》']
           },
              {
            week:['17'],
           classic:'礼尚往来',
           knowledge:['礼尚往来。往而不来，非礼也。'],
           power:['《礼记·曲礼上》']
           },
              {
            week:['18'],
           classic:'人一能之',
           knowledge:['人一能之，己百之。人十能之，己千之。'],
           power:['《中庸》']
           },
        ],
      midCourse:[
           {
            week:['1'],
           classic:' 鸿鹄之志',
           knowledge:['燕雀安知鸿鹄之志。'],
           power:['《史记·陈涉世家》']
           },
            {
            week:['2'],
           classic:'完璧归赵',
           knowledge:['城不入，臣请完璧归赵。'],
           power:['《史记·廉颇蔺相如列传》']
           },
        {
            week:['3'],
           classic:'舟过安仁',
           knowledge:['一叶渔船两小童，收篙停棹坐船中。怪生无雨都张伞，不是遮头是使风。'],
           power:['《舟过安仁》']
           },
           {
            week:['4'],
           classic:'约法三章',
           knowledge:['与父老约法三章耳；杀人者死，伤人及盗抵罪。'],
           power:['《史记·高祖本纪》']
           },
             {
            week:['5'],
           classic:'声东击西',
           knowledge:['将欲西而示之以东。'],
           power:['《淮南子·兵略训》']
           },
             {
            week:['6'],
           classic:'宿建德江',
           knowledge:['移舟泊烟渚，日暮客愁新。野旷天低树，江清月近人'],
           power:['《宿建德江》']
           },
             {
            week:['7'],
           classic:'取而代之',
           knowledge:['彼可取而代也。'],
           power:['《史记·项羽本纪》']
           },
             {
            week:['8'],
           classic:'四面楚歌',
           knowledge:['夜闻汉军四面皆楚歌，项王乃大惊'],
           power:['《史记·项羽本纪》']
           },
             {
            week:['9'],
           classic:'温故知新',
           knowledge:['学而时习之，不亦说乎？'],
           power:['《论语·学而》']
           },
             {
            week:['10'],
           classic:'渡浙江问舟中人',
           knowledge:['潮落江平未有风，扁舟共济与君同。时时引领望天末，何处青山是越中。'],
           power:['《渡浙江问舟中人》']
           },
             {
            week:['11'],
           classic:'项庄舞剑',
           knowledge:['今者项庄拔剑舞，其意常在沛公也。'],
           power:['《史记·项羽本纪》']
           },
             {
            week:['12'],
           classic:'前事不忘',
           knowledge:['今者项庄拔剑舞，其意常在沛公也。'],
           power:['《史记·秦始皇本纪》']
           },
             {
            week:['13'],
           classic:'咏柳',
           knowledge:['碧玉妆成一树高，万条垂下绿丝绦。不知细叶谁裁出，二月春风似剪刀。'],
           power:['《咏柳》']
           },
             {
            week:['14'],
           classic:'三人成虎',
           knowledge:['夫市之无虎明矣，然而三人言而成虎。'],
           power:['《战国策·魏策二》']
           },
             {
            week:['15'],
           classic:'仓廪实',
           knowledge:['仓廪实而知礼节，衣食足而知荣辱。'],
           power:['《史记·管晏列传》']
           },
             {
            week:['16'],
           classic:'山居秋暝',
           knowledge:['竹喧归浣女，莲动下渔舟。随意春芳歇，王孙自可留。'],
           power:['《山居秋暝》（节选）']
           },
             {
            week:['17'],
           classic:'人而无信',
           knowledge:['人而无信，不知其可也。'],
           power:['《论语·为政》']
           },
             {
            week:['18'],
           classic:'父母之年',
           knowledge:['父母之年，不可不知也。一则以喜，一则以惧。'],
           power:['《论语·里仁》']
           }
       ],
      showVideoBg: true,
    };
  },
  created() {},
  mounted() {},
  watch: {
    $route (to, from) {
      this.open = false
    },
  },
  methods: {
    // 播放视频
    videoPlay () {
      this.showVideoBg = false;
      let video = this.$refs.dailyPoetryVideo;
      let that = this;
      video.play()
      .then(res => {
        video.addEventListener('pause', () => {
          that.showVideoBg = true;
        })
       video.addEventListener('seeking',()=>{
         that.showVideoBg=false
       })
      }).catch(err => {
        
      })
    },
    openMask(flag) {
      if (flag === 4) {
        this.open = true;
        this.url =
          "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/enter-poetry.png";
      }
    },
  }
};
</script>

<style scoped>
.daily-poetry {
  width: 100%;
  min-width: 1120px;
  text-align: left;
}
.daily-bg {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1920px;
  max-height: 640px;
  margin: 0 auto;
  height: 640px;
  background: url(https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poetry-banner.png)
    no-repeat;
  background-size: 100% 640px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.banner-head {
  text-align: left;
  padding-left: 15px;
}
.banner-tip::after {
  content: "";
  width: 90px;
  height: 4px;
  border-radius: 2px;
  background-color: #fff;
  position: absolute;
  left: 0;
  bottom: -25px;
}
.banner-tip {
  font-size: 32px;
  color: #fff;
  margin-top: 14px;
  position: relative;
  margin-bottom: 64px;
}
.banner-head h1 {
  font-size: 54px;
  font-weight: bold;
  color: #ffffff;
}
 .banner-list{
    display: flex;
    color: #fff;
    align-items: center;
    }
    .banner-list p{
    line-height: 40px;
    font-size: 18px;
    }
    .banner-list img{
    object-fit: contain;
    margin-right: 10px;
    height: 18px;
    }
.daily-poetry .buy-btn {
  cursor: pointer;
  width: 240px;
  height: 60px;
  background: linear-gradient(
    270deg,
    rgba(250, 114, 24, 1) 0%,
    rgba(254, 143, 62, 1) 100%
  );
  border-radius: 30px;
  font-size: 26px;
  color: #ffffff;
  font-weight: bold;
  display: block;
  text-align: center;
  line-height: 60px;
  margin-top: 68px;
}
.read-icon{
  height: 404px;
 width: 597px;
 }
.banner {
  width: 100%;
  display: block;
  max-width: 1920px;
  max-height: 640px;
  margin: 0 auto;
  height: 640px;
  object-fit: cover;
  position: relative;
}
.container {
  width: 1120px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto;
}
/* .class-info {
  padding: 88px 0;
} */
.info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 44px;
}
.info-left {
  width: 320px;
  height: 600px;
  margin-right: 150px;
}
.video-player {
  width: 100%;
}
.video-bg{
  width: 320px;
  height: 556px;
}
.hide{
  display: none;
}
.info-right {
  flex: 1;
}
.info-title {
  font-size: 50px;
  font-family: MicrosoftYaHei-Bold;
  font-weight: bold;
  color: rgba(68, 65, 68, 1);
  margin-bottom: 50px;
  padding-top: 110px;
}
.info-detail {
  font-size: 20px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(68, 65, 68, 1);
  line-height: 40px;
}
.img-box {
  height: 310px;
  background: rgba(29, 186, 92, 1);
  border-radius: 20px;
  margin-top: 25px;
}
.img-center {
  margin: 0 110px 0 114px;
}
.img-left {
  margin: 0 114px 0 46px;
}
.content {
  padding-top: 60px;
  max-width: 521px;
}
.content-tit {
  font-size: 36px;
  font-family: MicrosoftYaHei-Bold;
  font-weight: bold;
  color: rgba(68, 65, 68, 1);
  margin-bottom: 35px;
}
.content-det {
  font-size: 20px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(68, 65, 68, 1);
  line-height: 40px;
}
.class-resule .img-box {
  margin-right: 65px;
}
.new-style .img-box,
.ai-mark .img-box {
  margin-left: 65px;
}
.schedule {
  background: rgba(246, 249, 249);
  padding: 73px 0 52px;
}
.book{
  background: #1EBA5C;
  padding: 80px 0 72px;
}
.book-contain{
  width: 1140px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}
.book-contain h2{
 font-size:50px;
font-family:PingFangSC-Medium;
font-weight:500;
color: #fff;
margin-bottom: 16px;
}
.book-btn {
  margin-bottom: 62px;
}
.book-btn img:not(:last-child){
  margin-right: 24px;
}
.book-img{
display: flex;
justify-content: space-between;
width: 100%;
}
h5.title {
  font-size: 50px;
  font-family: MicrosoftYaHei-Bold;
  font-weight: bold;
  color: rgba(68, 65, 68, 1);
  text-align: center;
  margin-bottom: 60px;
}
h5.title > span {
  color: rgba(29, 186, 92, 1);
}
.table-head span {
  background: rgba(29, 186, 92, 1);
  color: #fff;
  font-size: 26px;
  font-weight: bold;
}
.table-item {
  display: flex;
  justify-content: space-between;
}
.table-item span {
  display: block;
  height: 80px;
  line-height: 80px;
  text-align: center;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 8px;
  font-size: 24px;
}
span.first {
  width: 310px;
  border-radius: 10px 0px 0px 10px;
}
span.sec {
  width: 424px;
}
span.third {
  width: 370px;
  border-radius: 0px 10px 10px 0px;
}
.table-body > .table-item:nth-child(2n-1) span {
  background: rgba(235, 255, 243, 1);
}
.table-body .table-item:nth-child(2n) span {
  background: rgba(134, 227, 172, 1);
}
.table-sum {
  width: 100%;
  height: 80px;
  background: rgba(29, 186, 92, 1);
  border-radius: 10px;
  line-height: 80px;
  text-align: center;
  color: #fff;
  font-size: 26px;
  font-weight: bold;
}
.process img{
  width: 1120px;
  display: block;
  margin: 0 auto;
  height: 623px;
}
.commitment {
  overflow: hidden;
  position: relative;
  height: 684px;
  background: rgba(246, 245, 250);
}
.commitment img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  height: 650px;
  margin: 0 auto;
  z-index: 1;
  /* width: 100%; */
  object-fit: contain;
}
.commitment h5 {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  margin-bottom: 60px;
  padding-top: 60px;
}
</style>
